/*
  Props:
  player {
    kills
    assists
    deaths
    headshots
    damage
    firstkills
    firstdeaths
    clutchkills
    awpkills
    plants
    defuses
    flashassists
    flashduration
    grenadesthrown
    roundsplayed
    matchesplayed
  }
*/
import React from 'react';

const PlayerStats = (props) => {

  return (
    <div className='PlayerStats'>
      <div className='PSTop'>
        <div className='PSItem'>
          <div className='PSLabel'>Kills</div>
          <div className='PSContent'>{props.kills}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>Assists</div>
          <div className='PSContent'>{props.assists}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>Deaths</div>
          <div className='PSContent'>{props.deaths}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>K/D</div>
          <div className='PSContent'>{(props.kills/props.deaths).toFixed(2)}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>Headshots</div>
          <div className='PSContent'>{props.headshots}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>HS%</div>
          <div className='PSContent'>{((props.headshots/props.kills)*100).toFixed(1)}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>ADR</div>
          <div className='PSContent'>{props.damage}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>Utility Damage</div>
          <div className='PSContent'>{props.utilitydamage}</div>
        </div>
      </div>
      <div className='PSBot'>
        <div className='PSItem'>
          <div className='PSLabel'>First Kills</div>
          <div className='PSContent'>{props.firstkills}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>First Deaths</div>
          <div className='PSContent'>{props.firstdeaths}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>Clutch Kills</div>
          <div className='PSContent'>{props.clutchkills}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>AWP Kills</div>
          <div className='PSContent'>{props.awpkills}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>Plants</div>
          <div className='PSContent'>{props.plants}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>Defuses</div>
          <div className='PSContent'>{props.defuses}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>Flash Assists</div>
          <div className='PSContent'>{props.flashassists}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>Flash Enemy Duration</div>
          <div className='PSContent'>{props.flashduration}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>Grenades Thrown</div>
          <div className='PSContent'>{props.grenadesthrown}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>Rounds Played</div>
          <div className='PSContent'>{props.roundsplayed}</div>
        </div>
      </div>
      <div className='PSBot'>
        <div className='PSItem'>
          <div className='PSLabel'>3k</div>
          <div className='PSContent'>{props.k3}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>4k</div>
          <div className='PSContent'>{props.k4}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>5k</div>
          <div className='PSContent'>{props.k5}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>1v1</div>
          <div className='PSContent'>{props.v1}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>1v2</div>
          <div className='PSContent'>{props.v2}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>1v3</div>
          <div className='PSContent'>{props.v3}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>1v4</div>
          <div className='PSContent'>{props.v4}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>1v5</div>
          <div className='PSContent'>{props.v5}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>Wallbangs</div>
          <div className='PSContent'>{props.wallbangs}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>Noscopes</div>
          <div className='PSContent'>{props.noscopes}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>Blind Kills</div>
          <div className='PSContent'>{props.blindkills}</div>
        </div>
        <div className='PSItem'>
          <div className='PSLabel'>Smoke Kills</div>
          <div className='PSContent'>{props.smokebangs}</div>
        </div>
      </div>
    </div>
  );
};

export default PlayerStats;
