/*
  kills (headshots),
  assists,
  deaths,
  K/D +/-,
  ADR,
  FK Diff,
  Flash Enemy Duration,
*/
import React from 'react';
import SBPlayer from './SBPlayer';

const SBPlayers = (props) => {

  return (
    <div className='SBPlayers'>
      <div className='SBLabels'>
        <div title='Player Name' className='SBLabel'>Name</div>
        <div title='Kills' className='SBLabel'>K</div>
        <div title='Assists' className='SBLabel'>A</div>
        <div title='Deaths' className='SBLabel'>D</div>
        <div title='Headshots' className='SBLabel'>HS</div>
        <div title='Kill - Death Difference' className='SBLabel'>+/-</div>
        <div title='Average Damage per Round' className='SBLabel'>ADR</div>
        <div title='First Kill - First Death Difference' className='SBLabel'>FK</div>
        <div title='Flash Enemy Duration (seconds)' className='SBLabel'>FED</div>
      </div>
      <div className={props.swap ? 'SBPlayersT' : 'SBPlayersCT'}>
      {
        //Check players exist
        props.players && props.playermeta ?
          //Create each round
          props.players.map((player, i) => {
            if(player.team === 2) {
              return (
                <SBPlayer
                  key={i}
                  name={props.playermeta.hasOwnProperty(player.player_id) ? props.playermeta[player.player_id].name : 'Player'}
                  nickname={props.playermeta.hasOwnProperty(player.player_id) ? props.playermeta[player.player_id].nickname : 'Player'}
                  avatar={props.playermeta.hasOwnProperty(player.player_id) ? props.playermeta[player.player_id].avatar.small : 'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/fe/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb.jpg'}
                  id64={props.playermeta.hasOwnProperty(player.player_id) ? props.playermeta[player.player_id].id64 : ''}
                  kills={player.kills}
                  assists={player.assists}
                  deaths={player.deaths}
                  headshots={player.headshots}
                  damage={player.damage}
                  roundsplayed={player.roundsplayed}
                  firstkills={player.firstkills}
                  firstdeaths={player.firstdeaths}
                  flashduration={player.flashduration}
                />
              );
            } else {
              return null;
            }
          })
        :
          <p>No player data!</p>
      }
      </div>
      {
      }
      <div className={props.swap ? 'SBPlayersCT' : 'SBPlayersT'}>
      {
        //Check players exist
        props.players && props.playermeta ?
          //Create each round
          props.players.map((player, i) => {
            if(player.team === 1) {
              return (
                <SBPlayer
                  key={i}
                  name={props.playermeta.hasOwnProperty(player.player_id) ? props.playermeta[player.player_id].name : 'Player'}
                  nickname={props.playermeta.hasOwnProperty(player.player_id) ? props.playermeta[player.player_id].nickname : 'Player'}
                  avatar={props.playermeta.hasOwnProperty(player.player_id) ? props.playermeta[player.player_id].avatar.small : 'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/fe/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb.jpg'}
                  id64={props.playermeta.hasOwnProperty(player.player_id) ? props.playermeta[player.player_id].id64 : ''}
                  kills={player.kills}
                  assists={player.assists}
                  deaths={player.deaths}
                  headshots={player.headshots}
                  damage={player.damage}
                  roundsplayed={player.roundsplayed}
                  firstkills={player.firstkills}
                  firstdeaths={player.firstdeaths}
                  flashduration={player.flashduration}
                />
              );
            } else {
              return null;
            }
          })
        :
          <p>No player data!</p>
      }
      </div>
    </div>
  );
};

export default SBPlayers;
