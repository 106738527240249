import React from 'react';
import { Link } from 'react-router-dom';

const LiveMatchItem = (props) => {
  return (
    <div className='MatchItem'>
      <div className='MIBasic row'>
        <div className='MIDate col'>{props.timestamp} </div>
        <div className='MIMap col'>{props.map}</div>
        <div className='MIResult col'>{props.team1_name} {props.team1_score} - {props.team2_score} {props.team2_name}</div>
        <div className='MIActions col'>
          <Link to={'/live/'+props.matchKey}>
            <span className='MILink' title='Match Page'>
              <i className='fas fa-arrow-circle-right'></i>
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LiveMatchItem;
