import React, { useState, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useFetch } from '../hooks';
import Scoreboard from './Scoreboard';
import '../assets/stylesheets/match.css';
import RoundSlider from './RoundSlider';
import MatchInfo from './MatchInfo';
import MatchLog from './MatchLog';
import { apiUrl } from '../App';

function fetchFullMatch(matchid, players) {
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/getfullmatch/${matchid}`)
    .then(res => res.json())
    .then(json => {
      //console.dir(json.rounds);
      if(json.hasOwnProperty('error')) {
        reject(new Error(json.error));
      } else {
        resolve(json.rounds);
      }
    })
    .catch(err => {
      reject(new Error(err));
    });
  });
}
function getSteamData(playerid) {
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/getsteamsummary/${playerid}`)
    .then(res => res.json())
    .then(json => {
      if(json.hasOwnProperty('error')) {
        reject(new Error(json.error));
      } else {
        resolve(json);
      }
    })
    .catch(err => {
      reject(new Error(err));
    });
  });
}
function filterRounds(rounds, range) {
  var newrounds = [];
  newrounds = rounds.filter((round, i) => {
    return (i+1) >= range.min && (i+1) <= range.max;
  });
  return newrounds;
}
function minimiseRounds(rounds) {
  var newrounds = rounds.map((round, index) => {
    return {
      winner: round.winner,
      reason: round.reason
    };
  });
  return newrounds;
}
function scoreboardRounds(rounds, players) {
  var newplayers = {};
  var firstkillflag = false;
  if(Object.keys(players).length > 0 && rounds.length > 0) {
    Object.keys(players).forEach((key, i) => {
      newplayers[key] = {
        player_id: key,
        team: players[key].team,
        kills: 0,
        assists: 0,
        deaths: 0,
        headshots: 0,
        damage: 0,
        roundsplayed: rounds.length,
        firstkills: 0,
        firstdeaths: 0,
        flashduration: 0,
      };
    });
    rounds.forEach((round, i) => {
      firstkillflag = true;
      round.kill.forEach((kill, ki) => {
        if(players[kill.source_id].team !== players[kill.target_id].team) {
          //Killed an enemy
          newplayers[kill.source_id].kills ++;
          if(kill.headshot === 1) newplayers[kill.source_id].headshots ++;
          if(firstkillflag) {
            newplayers[kill.source_id].firstkills ++;
            newplayers[kill.target_id].firstdeaths ++;
            firstkillflag = false;
          }
        } else {
          //Teamkill
          newplayers[kill.source_id].kills --;
        }
        newplayers[kill.target_id].deaths ++;
      });
      round.assist.forEach((ass, ai) => {
        newplayers[ass.source_id].assists ++;
      });
      round.playerdamage.forEach((dmg, di) => {
        newplayers[dmg.source_id].damage += dmg.value;
      });
      round.flash.forEach((flash, fi) => {
        newplayers[flash.source_id].flashduration += flash.duration;
      });
    });
  }
  var playerArray = [];
  Object.keys(newplayers).forEach((player) => {
    if(newplayers.hasOwnProperty(player)) {
      playerArray.push(newplayers[player]);
    }
  });
  //console.dir(playerArray);
  return playerArray;
}

function roundRangeScore(rounds, range) {
  if(!rounds[range.max-1]) return roundRangeScore(rounds, {min: 1, max: 2});
  if(range.max === range.min) {
    return {
      team1: rounds[range.max-1].winner === 1 ? 1 : 0,
      team2: rounds[range.max-1].winner === 2 ? 1 : 0
    };
  } else if(range.min === 1) {
    return {
      team1: rounds[range.max-1].team1_score,
      team2: rounds[range.max-1].team2_score
    };
  } else {
    return {
      team1: rounds[range.max-1].team1_score - rounds[range.min-2].team1_score,
      team2: rounds[range.max-1].team2_score - rounds[range.min-2].team2_score
    };
  }
}

function sortPlayers(players) {
  if(players && players.length > 0) {
    return players.sort(function(a, b) {
      return b.team - a.team || b.kills - a.kills;
    });
  } else {
    return players;
  }
}

function areSidesSwapped(roundnumber) {
  if(roundnumber < 16 || (roundnumber > 33 && roundnumber < 40) || (roundnumber > 42 && roundnumber < 49) || (roundnumber > 51 && roundnumber < 58) || (roundnumber > 60 && roundnumber < 67) || (roundnumber > 69 && roundnumber < 76)) {
    return true;
  } else {
    return false;
  }
}

const Match = () => {
  const { id } = useParams()
  const [matchdata, matchloading] = useFetch(`${apiUrl}/getmatch/`, id);
  const [rounds, setRounds] = useState([]);
  const [roundrange, setRoundRange] = useState(
    {min: 1, max: 16}
  );
  /*const [filteredrounds, setFilteredRounds] = useState([]);*/
  const [players, setPlayers] = useState({});
  //Get steam nicknames and avatars for players in match
  useEffect(() => {
    if(matchdata.hasOwnProperty('players')) {
      matchdata.players.forEach((player, i) => {
        setPlayers(pl => ({...pl, [player.player_id]: {
          name: player.name,
          team: player.team,
          avatar: {
            large: 'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/fe/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg',
            medium: 'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/fe/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_medium.jpg',
            small: 'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/fe/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb.jpg',
          },
          nickname: player.name,
          id64: player.id64,
        }}));
        getSteamData(player.player_id)
        .then(data => {
          setPlayers(pl => ({...pl, [player.player_id]: {
            name: player.name,
            team: player.team,
            avatar: data.avatar,
            nickname: data.nickname,
            id64: data.id64,
          }}));
        })
        .catch(err => console.log(err));
      });
    }
  }, [matchdata]);
  //Update roundrange with rounds from match
  useEffect(() => {
    setRoundRange({min: 1, max: rounds.length});
  }, [rounds]);
  //Update filtered rounds when new round range set
  /*useEffect(() => {
    setFilteredRounds(filterRounds(rounds, roundrange));
  }, [rounds, roundrange]);*/

  return (
    <div className='Match col'>
      {
        matchdata.error ?
        <Redirect
          to={'/'}
        />
        :
        ''
      }
      <div className='PartTop row'>
        <div className='PTBG' style={matchloading || matchdata.error ?
        {} :
        {
          background: 'url('+require('../assets/images/maps/'+matchdata.match.map+'.jpg')+')'
        }}></div>
        <div className='PTContent col'>
          {
            !matchloading && Object.keys(players).length > 0 ?
              <div>
                <MatchInfo
                  organiser={matchdata.match.organiser}
                  organiser_url={matchdata.match.organiser_url}
                  tournament={matchdata.match.tournament}
                  tournament_url={matchdata.match.tournament_url}
                  date={matchdata.match.datetime}
                  duration={matchdata.match.duration}
                  map={matchdata.match.map}
                  demo={matchdata.match.demo}
                />
                <Scoreboard
                  swap={rounds.length > 0 ? areSidesSwapped(roundrange.max) : false}
                  teamnames={{
                    team1: matchdata.match.team1_name,
                    team2: matchdata.match.team2_name,
                  }}
                  scores={{
                    team1: rounds.length > 0 ? roundRangeScore(rounds, roundrange).team1 : matchdata.match.team1_score,
                    team2: rounds.length > 0 ? roundRangeScore(rounds, roundrange).team2 : matchdata.match.team2_score,
                  }}
                  playermeta={players}
                  players={rounds.length > 0 ? sortPlayers(scoreboardRounds(filterRounds(rounds, roundrange), players)) : sortPlayers(matchdata.players)}
                />
                {
                  rounds.length > 0 ?
                  ''
                  :
                  <div className='AdvMatchButton'>
                  <button
                    type='button'
                    className='btn btn-light'
                    onClick={() => {
                      fetchFullMatch(id, players)
                      .then(rounds => setRounds(rounds))
                    }}
                  >
                  Load Round Slider
                  </button>
                  </div>
                }
              </div>
            :
              'MATCH LOADING'
          }
          <RoundSlider
            setRoundRange={setRoundRange}
            rounds={minimiseRounds(rounds)}
            maxrounds={rounds.length}
          />
        </div>
      </div>
      <div className='PartBot row'>
        {
          rounds.length > 0 ?
          <MatchLog />
          :
          ''
        }
      </div>
    </div>
  );
};

export default Match;
