import React, { useState, useEffect } from 'react';
import LiveMatchItem from './LiveMatchItem';
import { socket } from '../App';
import { merge } from 'deepmerge';

const LiveBrowse = (props) => {
  const [matches, setMatches] = useState(null);

  useEffect(() => {
    socket.emit('browse', null, (res) => {
      if (res.matches) setMatches(res.matches);
      else setMatches({error: true});
    });
    return () => {
      socket.emit('leaveRoom', `browse`);
    }
  }, []);
  useEffect(() => {
    socket.on('matchesUpdate', (data) => {
      if (data.key === "browse") setMatches(merge(matches, data.data));
    });
    socket.on('deleteMatch', (data) => {
      if (data.key === "browse" && matches.hasOwnProperty(data.matchKey)) delete matches[data.matchKey];
    });
  }, []);

  return (
    <div className='PlayerMatchList'>
      <div className='MLTitle'>
        Matches Currently Live
      </div>
      <div className='MLHeaders row'>
        <div className='col'>Tournament</div>
        <div className='col'>Date</div>
        <div className='col'>Map</div>
        <div className='col'>Result</div>
        <div className='col'>Actions</div>
      </div>
      <div className='MLContent'>
      {
        matches && Object.keys(matches).length > 0 ?
        Object.keys(matches).map((match, i) => {
          return (
            <LiveMatchItem
              key={i}
              matchKey={match}
              timestamp={matches[match].timestamp}
              team1_score={matches[match].team1.score}
              team1_name={matches[match].team1.name}
              team2_score={matches[match].team2.score}
              team2_name={matches[match].team2.name}
              fixture={matches[match].fixture}
              map={matches[match].map}
            />
          );
        }) :
        <p>No current live matches!</p>
      }
      </div>
    </div>
  );
};

export default LiveBrowse;
