import React, { useState, useEffect } from 'react';
import InputRange from 'react-input-range';
import RoundEndIcon from './RoundEndIcon';

const RoundSlider = (props) => {
  const [value, setValue] = useState(
    {min: 1, max: props.maxrounds > 0 ? props.maxrounds : 16}
  );
  useEffect(() => {
    setValue({min: 1, max: props.maxrounds});
  }, [props.maxrounds]);
  return (
  <div className='RoundSlider'>
    <div className='RSRounds'>
      {
        props.rounds.length > 0 ?
        props.rounds.map((round, index) => {
          return (
            <div key={index} className='RSRound'>
              <div className={
                index+1 >= value.min && index+1 <= value.max ?
                'RSRoundIcon RSActive'
                :
                'RSRoundIcon'
              }>
                <div className={
                  round.winner === 2 ?
                      'RSTopHalf'
                    :
                      'RSBotHalf'
                }>
                  <RoundEndIcon type={round.reason} teamwon={round.winner}  round={index+1} />
                </div>
              </div>
            </div>
          );
        })
        :
        ''
      }
    </div>
    {
      props.rounds.length > 0 ?
      <InputRange
        maxValue={props.rounds.length}
        minValue={1}
        value={value}
        allowSameValues={true}
        onChange={value => setValue( value )}
        onChangeComplete={value => props.setRoundRange( value )}
      />
      :
      ''
    }
  </div>
  )
}

export default RoundSlider;
