import React, { useState, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Scoreboard from '../match/Scoreboard';
import '../assets/stylesheets/match.css';
import MatchInfo from '../match//MatchInfo';
import { apiUrl, socket } from '../App';
import merge from 'deepmerge';

function getSteamData(playerid) {
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/getsteamsummary/${playerid}`)
    .then(res => res.json())
    .then(json => {
      if(json.hasOwnProperty('error')) {
        reject(new Error(json.error));
      } else {
        resolve(json);
      }
    })
    .catch(err => {
      reject(new Error(err));
    });
  });
}

function getTournData(tournId) {
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/gettournamentmeta/${tournId}`)
    .then(res => res.json())
    .then(json => {
      if(json.hasOwnProperty('error')) {
        reject(new Error(json.error));
      } else {
        resolve(json);
      }
    })
    .catch(err => {
      reject(new Error(err));
    });
  });
}

function sortPlayers(players) {
  const arr = [];
  Object.keys(players).forEach(player => {
    players[player].player_id = player;
    arr.push(players[player]);
  });
  if(arr && arr.length > 0) {
    return arr.sort(function(a, b) {
      return b.team - a.team || b.kills - a.kills;
    });
  } else {
    return arr;
  }
}

function areSidesSwapped(roundnumber) {
  if(roundnumber < 16 || (roundnumber > 33 && roundnumber < 40) || (roundnumber > 42 && roundnumber < 49) || (roundnumber > 51 && roundnumber < 58) || (roundnumber > 60 && roundnumber < 67) || (roundnumber > 69 && roundnumber < 76)) {
    return true;
  } else {
    return false;
  }
}

const LiveMatch = () => {
  const { matchKey } = useParams()
  //const [matchdata, matchloading] = useFetch(`${apiUrl}/getmatch/`, id);
  const [matchdata, setMatch] = useState({});
  const [players, setPlayers] = useState({});
  const [tourndata, setTournData] = useState({});
  const [newmatch, setNewMatch] = useState(null);
  //Get steam nicknames and avatars for players in match
  useEffect(() => {
    if(matchdata.hasOwnProperty('players')) {
      Object.keys(matchdata.players).forEach(player => {
        if (!players.hasOwnProperty(player)) {
          setPlayers(pl => ({...pl, [player]: {
            name: matchdata.players[player].name,
            team: matchdata.players[player].team,
            avatar: {
              large: 'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/fe/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg',
              medium: 'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/fe/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_medium.jpg',
              small: 'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/fe/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb.jpg',
            },
            nickname: matchdata.players[player].name,
            id64: null,
          }}));
          getSteamData(player)
          .then(data => {
            setPlayers(pl => ({...pl, [player]: {
              name: matchdata.players[player].name,
              team: matchdata.players[player].team,
              avatar: data.avatar,
              nickname: data.nickname,
              id64: data.id64,
            }}));
          })
          .catch(err => console.log(err));
        }
      });
    }
  }, [matchdata]);

  useEffect(() => {
    if (Object.keys(tourndata).length < 1 && matchdata.tournament) {
      getTournData(matchdata.tournament)
      .then(data => {
        setTournData(data);
      });
    }
  }, [matchdata, tourndata]);

  useEffect(() => {
    socket.emit('checkUrl', matchKey, (res) => {
      console.log(res.match);
      if (res.match) setMatch(res.match);
      else setMatch({error: true});
    });
    return () => {
      socket.emit('leaveRoom', matchKey);
    }
  }, []);
  useEffect(() => {
    socket.on('matchUpdate', (data) => {
      console.log({matchdata: matchdata, newdata: data, merged: merge(matchdata, data.data)});
      if (data.key === matchKey) {
        if (data.action === "matchstart") setMatch(match => {return {...match, players: {}} });
        setMatch(match => merge(match, data.data));
      }
    });
    socket.on('matchFinished', (data) => {
      if (data.key === matchKey) {
        const answer = window.confirm("This map has finished, would you like to continue to the finished map page?");
        if (answer) setNewMatch(data.id);
      }
    });
  }, []);

  return (
    <div className='Match col'>
      {
        matchdata.error || newmatch ?
        <Redirect
          to={newmatch ? `/match/${newmatch}` : '/live'}
        />
        :
        ''
      }
      <div className='PartTop row'>
        <div className='PTBG' style={matchdata.error || !matchdata.map ?
        {} :
        {
          background: 'url('+require('../assets/images/maps/'+matchdata.map+'.jpg')+')'
        }}></div>
        <div className='PTContent col'>
          {
            Object.keys(tourndata).length > 0 && Object.keys(matchdata).length > 0 && Object.keys(players).length > 0 ?
              <div>
                <MatchInfo
                  organiser={tourndata.organiser}
                  organiser_url={tourndata.organiser_url}
                  tournament={tourndata.tournament}
                  tournament_url={tourndata.tournament_url}
                  date={matchdata.timestamp}
                  fixture={matchdata.fixture}
                  map={matchdata.map}
                />
                <Scoreboard
                  swap={areSidesSwapped(matchdata.team1.score + matchdata.team2.score)}
                  teamnames={{
                    team1: matchdata.team1.name,
                    team2: matchdata.team2.name,
                  }}
                  scores={{
                    team1: matchdata.team1.score,
                    team2: matchdata.team2.score,
                  }}
                  playermeta={players}
                  players={sortPlayers(matchdata.players)}
                />
              </div>
            :
              'MATCH LOADING'
          }
        </div>
      </div>
      <div className='PartBot row'>
      </div>
    </div>
  );
};

export default LiveMatch;
