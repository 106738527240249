import React, { useEffect } from 'react';
//import './App.css';
import { Switch, Route } from 'react-router-dom';
import Header from './common/Header';
import Footer from './common/Footer';
import Main from './main/Main';
import Player from './player/Player';
import Match from './match/Match';
import Search from './main/Search';
import { withTracker } from './common/WithTracker';
import io from 'socket.io-client';
import LiveBrowse from './live/LiveBrowse';
import LiveMatch from './live/LiveMatch';

export const apiUrl = 'https://api.stadistics.com';
//export const apiUrl = 'http://localhost:3003';
export const wsUrl = 'https://live.stadistics.com';
export const socket = io(wsUrl);

const App = () => {
  /*useEffect(() => {
    socket = io(wsUrl);
    return () => socket.disconnect();
  });*/
  return (
    <div className='App'>
      <Header />
      <div className='AppContent container-md'>
        <div className='row'>
          <Switch>
            <Route exact path='/player/:id' component={withTracker(Player)} />
            <Route exact path='/match/:id' component={withTracker(Match)} />
            <Route path='/search' component={withTracker(Search)} />
            <Route path='/browse/:id' component={withTracker(Search)} />
            <Route exact path='/live/' component={withTracker(LiveBrowse)} />
            <Route path='/live/:matchKey' component={withTracker(LiveMatch)} />
            <Route path='/' component={withTracker(Main)} />
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
