import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { plusminus } from '../common/utility'

const MatchItem = (props) => {
  const [expandedMatch, toggleExpanded] = useState(false);

  return (
    <div className='MatchItem'>
      <div className='MIBasic row'>
        <div className='MISeason col'>{props.match.organiser + ' ' + props.match.tournament}</div>
        <div className='MIDate col'>{props.match.datetime} </div>
        <div className='MIMap col'>{props.match.map}</div>
        <div className='MIResult col'>{props.match.team1_score} - {props.match.team2_score} ({props.isWin ? 'W' : 'L'})</div>
        <div className='MIActions col'>
          <span className='MIExpand' title='Expand Stats' onClick={event => toggleExpanded(!expandedMatch)}>
            <i className='fas fa-chevron-down'></i>
          </span>
          <Link to={'/match/'+props.match.matchid}>
            <span className='MILink' title='Match Page'>
              <i className='fas fa-arrow-circle-right'></i>
            </span>
          </Link>
        </div>
      </div>
      {
        expandedMatch ?
        <div className='MIExtra row'>
          <div title='Kills' className='col'>K: {props.match.kills}</div>
          <div title='Assists' className='col'>A: {props.match.assists}</div>
          <div title='Deaths' className='col'>D: {props.match.deaths}</div>
          <div title='Headshots' className='col'>HS: {props.match.headshots}</div>
          <div title='Average Damage Per Round' className='col'>ADR: {(props.match.damage/props.match.roundsplayed).toFixed(2)}</div>
          <div title='First Kill - First Death Difference' className='col'>FK: {plusminus(props.match.firstkills - props.match.firstdeaths)}</div>
          <div title='Clutch Kills' className='col'>CK: {props.match.clutchkills}</div>
          <div title='AWP Kills' className='col'>AWP: {props.match.awpkills}</div>
          <div title='Grenades Thrown' className='col'>Gren: {props.match.grenadesthrown}</div>
          <div title='Flash Enemy Duration' className='col'>FED: {props.match.flashduration.toFixed(2)}</div>
        </div>
        :
        ''
      }
    </div>
  );
};

export default MatchItem;
