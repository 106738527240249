import React from 'react';

const Footer = () => {
  return (
    <footer className='Footer'>
      <div><small>Display names and pictures from Steam API</small></div>
      <div><small>Data from server logs & demos from tournament organisers</small></div>
    </footer>
  );
};

export default Footer;
