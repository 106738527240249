import React from 'react';
import { Link } from 'react-router-dom';
import ProfileSearch from '../common/ProfileSearch';

const Header = () => {
  return (
    <header className='Header'>
      <div className='container-md'>
        <div className='row'>
          <div className='HomeLink col'>
            <Link to='/'>Stadistics</Link>
          </div>
          <div className='HeaderSearch col'>
            <div className='HeaderSearchLabel'>
              Enter a Steam Profile:
            </div>
            <ProfileSearch />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
