// hooks.js
import { useState, useEffect } from 'react';
function useFetch(url, param = '', text = false) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const abortController = new AbortController();
  const signal = abortController.signal;

  async function fetchUrl() {
    const response = await fetch(url + param, { signal: signal });
    const json = text ? await response.text() : await response.json();
    //console.dir(json);
    setData(json);
    setLoading(false);
  }

  useEffect(() => {
    fetchUrl();
    return function cleanup() { abortController.abort() }
  }, [param]);
  return [data, loading];
}
export { useFetch };
