import React from 'react';
import LiveMatchItem from './LiveMatchItem';

const LivePlayerMatchList = (props) => {
  return (
    <div className='PlayerMatchList'>
      <div className='MLTitle'>
        LIVE right now in:
      </div>
      <div className='MLHeaders row'>
        <div className='col'>Started</div>
        <div className='col'>Map</div>
        <div className='col'>Scores</div>
        <div className='col'>Match Link</div>
      </div>
      <div className='MLContent'>
      {
        props.matches ?
        Object.keys(props.matches).map((match, i) => {
          return (
            <LiveMatchItem
              key={i}
              matchKey={match}
              timestamp={props.matches[match].timestamp}
              team1_score={props.matches[match].team1.score}
              team1_name={props.matches[match].team1.name}
              team2_score={props.matches[match].team2.score}
              team2_name={props.matches[match].team2.name}
              fixture={props.matches[match].fixture}
              map={props.matches[match].map}
            />
          );
        }) :
        ''
      }
      </div>
    </div>
  );
};

export default LivePlayerMatchList;
