import React from 'react';
import MatchItem from './MatchItem';

const PlayerMatchList = (props) => {

  return (
    <div className='PlayerMatchList'>
      <div className='MLTitle'>
        Match Archive
      </div>
      <div className='MLHeaders row'>
        <div className='col'>Tournament</div>
        <div className='col'>Date</div>
        <div className='col'>Map</div>
        <div className='col'>Result</div>
        <div className='col'>Actions</div>
      </div>
      <div className='MLContent'>
      {
        props.playerloading ?
          <div>LOADING MATCHES</div>
        :
        props.matches ?
        props.matches.map((match, i) => {
          return (
            <MatchItem
              key={i}
              match={match}
              isWin={props.isWin(match)}
            />
          );
        }) :
        <p>No matches found for player!</p>
      }
      </div>
    </div>
  );
};

export default PlayerMatchList;
