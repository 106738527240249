import React, { useState, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useFetch } from '../hooks';
import PlayerStatsContainer from './PlayerStatsContainer';
import PlayerMatchList from './PlayerMatchList';
import PlayerDetails from './PlayerDetails';
import LivePlayerMatchList from '../live/LivePlayerMatchList';
import '../assets/stylesheets/player.css';
import Select from 'react-select';
import { apiUrl, socket } from '../App';
import { merge } from 'deepmerge';

function playerAverages(matches) {
  if(matches){
    var player = {
      kills: 0,
      assists: 0,
      deaths: 0,
      headshots: 0,
      wallbangs: 0,
      noscopes: 0,
      blindkills: 0,
      smokebangs: 0,
      damage: 0,
      roundsplayed: 0,
      firstkills: 0,
      firstdeaths: 0,
      clutchkills: 0,
      awpkills: 0,
      plants: 0,
      defuses: 0,
      flashassists: 0,
      flashduration: 0,
      grenadesthrown: 0,
      utilitydamage: 0,
      matchesplayed: matches.length,
      matches_fa: 0,
      matches_kf: 0,
      k3: 0,
      k4: 0,
      k5: 0,
      v1: 0,
      v2: 0,
      v3: 0,
      v4: 0,
      v5: 0,
    };
    matches.forEach(function(match, i) {
      player.kills += match.kills;
      player.assists += match.assists;
      player.deaths += match.deaths;
      player.headshots += match.headshots;
      player.wallbangs += match.wallbangs;
      player.noscopes += match.noscopes;
      player.blindkills += match.blindkills;
      player.smokebangs += match.smokebangs;
      player.damage += match.damage;
      player.roundsplayed += match.roundsplayed;
      player.firstkills += match.firstkills;
      player.firstdeaths += match.firstdeaths;
      player.clutchkills += match.clutchkills;
      player.awpkills += match.awpkills;
      player.plants += match.plants;
      player.defuses += match.defuses;
      player.flashassists += match.flashassists;
      player.flashduration += match.flashduration;
      player.grenadesthrown += match.grenadesthrown;
      player.utilitydamage += match.utilitydamage;
      if (new Date(match.datetime) > new Date('2019-03-14')) player.matches_fa ++;
      if (new Date(match.datetime) > new Date('2020-05-05')) player.matches_kf ++;
      player.k3 += match['3k'];
      player.k4 += match['4k'];
      player.k5 += match['5k'];
      player.v1 += match['1v1'];
      player.v2 += match['1v2'];
      player.v3 += match['1v3'];
      player.v4 += match['1v4'];
      player.v5 += match['1v5'];
    });
    return player;
  }
}

function mapStats(matches) {
  if(matches) {
    var mapStats = {};
    matches.forEach(function(match, i) {
      if(mapStats.hasOwnProperty(match.map)) {
        if(isWin(match)) {
          mapStats[match.map].wins ++;
        } else {
          mapStats[match.map].losses ++;
        }
      } else {
        if(isWin(match)) {
          mapStats[match.map] = {name: match.map, wins: 1, losses: 0}
        } else {
          mapStats[match.map] = {name: match.map, wins: 0, losses: 1}
        }
      }
    });
    var mapsArray = [];
    Object.keys(mapStats).forEach((map) => {
      if(mapStats.hasOwnProperty(map)) {
        mapsArray.push(mapStats[map]);
      }
    });
    return mapsArray.sort((a,b) => (a.wins < b.wins) ? 1 : ((b.wins < a.wins) ? -1 : 0));
  }
}

function isWin(match) {
  if(match) {
    if((match.team === 1 && match.team1_score > match.team2_score) || (match.team === 2 && match.team1_score < match.team2_score)) {
      return true;
    }
  }
  return false;
}

function filterMatches(matches, filters) {
  var filteredMatches = matches;
  //Filter by organisers selected
  if(filters.organiser && filters.organiser.length > 0) {
    filteredMatches = matches.filter((match) => {
      return filters.organiser.some((f) => {
        return f === match.organiser;
      });
    });
    //Filter by tournaments selected
  } else if(filters.tournament && filters.tournament.length > 0) {
    filteredMatches = matches.filter((match) => {
      return filters.tournament.some((f) => {
        return f.value === match.tournament;
      });
    });
  }
  //Filter between dates set
  if(filteredMatches) {
    filteredMatches = filteredMatches.filter((match) => {
      return Date.parse(match.datetime) >= filters.startdate &&
            Date.parse(match.datetime) <= filters.enddate;
    });
  }
  return filteredMatches;
}

async function fetchSteamID(id) {
  try {
    const steamidResponse = await fetch(`${apiUrl}/getogsteamid/${encodeURIComponent(id)}`);
    const steamid = await steamidResponse.text();
    return steamid;
  }
  catch (e) {
    console.error(e);
  }
}

const Player = () => {
  const { id } = useParams();
  const [summary, summaryloading] = useFetch(`${apiUrl}/getsteamsummary/`, id);
  const [playerdata, playerloading] = useFetch(`${apiUrl}/getplayer/`, id);
  const [steamId, steamIdLoading] = useFetch(`${apiUrl}/getogsteamid/`, id, true);
  const [matches, setMatches] = useState(
    []
  );
  const [tournaments, setTournaments] = useState();
  //const [organisers, setOrganisers] = useState();
  const [options, setOptions] = useState();
  const [isFilters, setIsFilters] = useState(false);
  const [filters, setFilters] = useState(
    {
      organiser: [],
      tournament: [],
      startdate: 0,
      enddate: Date.now(),
    }
  );
  const [liveMatches, setLiveMatches] = useState({});
  //console.dir(playerdata);
  //Update matches state with matches from data
  useEffect(() => {
    if (!playerdata.error) setMatches(filterMatches(playerdata, filters));
    //setTournaments([...new Set(playerdata.map(x => x.tournament))].map(x => {return {'value': x, 'label': x}}));
    //setOrganisers([...new Set(playerdata.map(x => x.organiser))]);
  }, [playerdata, filters]);
  useEffect(() => {
    if (!playerdata.error) setTournaments([...new Set(playerdata.map(x => x.tournament))].map(x => {return {'value': x, 'label': x}}));
    //setOrganisers([...new Set(playerdata.map(x => x.organiser))]);
  }, [playerdata]);
  useEffect(() => {
    setOptions(tournaments);
  }, [tournaments]);

  useEffect(() => {
    socket.emit('checkPlayer', `${steamId}`, (res) => {
      setLiveMatches(res)
    });
    return () => {
      socket.emit('leaveRoom', `${steamId}`)
    }
  }, [steamId]);
  useEffect(() => {
    socket.on('matchesUpdate', (data) => {
      if (data.key === steamId) setLiveMatches(merge(liveMatches, data.data));
    });
    socket.on('deleteMatch', (data) => {
      if (data.key === steamId && liveMatches.hasOwnProperty(data.matchKey)) delete liveMatches[data.matchKey];
    });
  }, []);

  return (
    <div className='Player col'>
      {
        playerdata.error ?
        <Redirect
          to={'/'}
        />
        :
        ''
      }
      <div className='row'>
        <div className='PTContent'>
          <div className='PartTop'>
          <div className='PTBG' style={playerloading ?
          {} : matches.length < 1 ? {} :
          {
            background: 'url('+require('../assets/images/maps/'+matches[0].map+'.jpg')+')'
          }}></div>
          <PlayerDetails
            matchesplayed={playerloading ? 0 : matches.length}
            nickname={summaryloading || summary.error ? id : summary.nickname}
            avatar={summaryloading || summary.error ? "https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/fe/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg" : summary.avatar.large}
            id64={summaryloading || summary.error ? id : summary.id64}
          />
          {
            playerloading ?
              'LOADING PLAYER'
            :
            matches.length > 0 ?
            <PlayerStatsContainer
              player={playerAverages(matches)}
              maps={mapStats(matches)}
            />
            :
              'No stats found :('
          }
          {
            !steamIdLoading && liveMatches && Object.keys(liveMatches).length > 0 ?
            <LivePlayerMatchList
              playerloading={steamIdLoading}
              matches={liveMatches}
            />
            :
            ''
          }
          </div>
          <div className='SortFilter'>
            <div
            className='SFHeader'
            title='Filter and Sort'
            onClick={event => setIsFilters(!isFilters)}>
              <span>Filter</span>
              <i className='fas fa-filter'></i>
              <i className='fas fa-sort'></i>
            </div>
            {
              isFilters ?
              <div className='FilterContent row'>
                <div className='col-md-6'>
                  <div className='PSLabel'>Tournaments</div>
                  <Select isMulti
                    onChange={values => {
                      setOptions(values);
                      setFilters({...filters, tournament: values});
                    }}
                    isLoading={!tournaments}
                    options={tournaments}
                    value={options}
                    placeholder='Tournaments'
                  />
                </div>
                <div className='FCDate col-6 col-md-3'>
                <div className='PSLabel'>Date From</div>
                  <input className='form-control' type='date' onChange={event => setFilters({...filters, startdate: Date.parse(event.target.value)})} />
                </div>
                <div className='FCDate col-6 col-md-3'>
                  <div className='PSLabel'>Date To</div>
                  <input className='form-control' type='date' onChange={event => setFilters({...filters, enddate: Date.parse(event.target.value)})} />
                </div>
              </div>
              :
              ''
            }
            <div className='SortContainer'>
              Sort Type: ASC/DESC
              Sort By: K/D/etc
            </div>
          </div>
          <PlayerMatchList
            playerloading={playerloading}
            matches={matches}
            isWin={isWin}
          />
        </div>
      </div>
    </div>
  );
};

export default Player;
