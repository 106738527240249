import React from 'react';
import ProfileSearch from '../common/ProfileSearch';
import '../assets/stylesheets/main.css';

const Main = () => {
  return (
    <div className='Main col'>
      <div className='col-md-10 offset-md-1'>
        <p>See your average UK University CS:GO tournament stats and matches played. Currently includes NUEL seasons from Spring 2018 to present, some matches may be missing or incorrect.</p>
        <p>Enter any kind of SteamID, including vanity and URLs (but not display names):</p>
        <ProfileSearch />
      </div>
    </div>
  );
};

export default Main;
