import React from 'react';
import { Link } from 'react-router-dom';

const SearchResult = (props) => {
  return (
    <div className='SR'>
      <div className='SRName'><Link to={'/player/'+props.player_id}>{props.name_list.join(", ")}</Link></div>
    </div>
  );
};

export default SearchResult;
