/*
  Props:
  matchesplayed = matches played
  nickname = player nickname
  avatar = player avatar
  id64 = player id64
*/

import React from 'react';

const PlayerDetails = (props) => {

  return (
    <div className='PlayerDetails'>
      <div className='col'>
        <div className='row align-items-end'>
          <div className='PDAvatar'><img alt='Steam Avatar' src={props.avatar} /></div>
          <div className='PDText'>
            <div className='PDName'><a href={'https://steamcommunity.com/profiles/'+props.id64}>{props.nickname}</a></div>
            <div className='PDPlayed'>Statistics built from {props.matchesplayed} matches played</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerDetails;
