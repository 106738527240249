import React from 'react';

const PlayerMapStats = (props) => {

  return (
    <div className='PlayerMapStats'>
    {
      props.maps ?
      props.maps.map((map, i) => {
        return (
          <p key={i}>{map.name} - W:{map.wins} - L:{map.losses}</p>
        );
      }) :
      <p>No matches found for player!</p>
    }
    </div>
  );
};

export default PlayerMapStats;
