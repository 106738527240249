import React from 'react';
import Players from './SBPlayers';

const Scoreboard = (props) =>
<div className='Scoreboard'>
  <div className='Teamnames row'>
    <div className={'TeamnameL ' + (props.swap ? 'TeamnameT' : 'TeamnameCT')}>
      <div className='Teamname'>{props.teamnames.team2}</div>
      <div className='Teamscore'>{props.scores.team2}</div>
    </div>
    <div className={'TeamnameR ' + (props.swap ? 'TeamnameCT' : 'TeamnameT')}>
      <div className='Teamscore'>{props.scores.team1}</div>
      <div className='Teamname'>{props.teamnames.team1}</div>
    </div>
  </div>
  <Players {...props} />
</div>

export default Scoreboard;
