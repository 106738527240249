import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { apiUrl } from '../App';

 async function fetchSteamID(userinput) {
   try {
     const steamidResponse = await fetch(`${apiUrl}/getsteamid/${encodeURIComponent(userinput)}`);
     const steamid = await steamidResponse.text();
     if (/^\d{17}$/.test(steamid)) {
       const existsResponse = await fetch(`${apiUrl}/checkplayer/${steamid}`);
       const exists = await existsResponse.json();
       return exists ? {location: 'player', steamid: steamid, userinput: userinput} : {location: 'search', steamid: steamid, userinput: userinput};
     }
     return {location: 'search', steamid: null, userinput: userinput};
   }
   catch (e) {
     console.error(e);
   }
 }


const ProfileSearch = () => {
  const [searchResult, setSearchResult] = useState(
    {
      location: null,
      steamid: null,
      userinput: null,
    }
  );
  const [userinput, setUserInput] = useState(
    ''
  );
  return (
    <div className='ProfileSearch'>
      <form onSubmit={(e) => {
        e.preventDefault();
        fetchSteamID(userinput)
        .then(res => setSearchResult(res));
      }}>
        <input
          type='text'
          value={userinput}
          onChange={event => setUserInput(event.target.value)}
        />
        <input
          type='submit'
          value='Go'
        />
      </form>
      {
        searchResult.location ?
        searchResult.location === 'player' ?
        <Redirect
          push
          to={'/player/'+searchResult.steamid}
        />
        :
        <Redirect
          push
          to={{
            pathname: '/search',
            state: {
              userinput: searchResult.userinput,
              steamid: searchResult.steamid,
            }
          }}
        />
        :
        ''
      }
    </div>
  );
};

export default ProfileSearch;
