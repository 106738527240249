import React from 'react';

function getDate(timestamp) {
  var date = new Date(timestamp);
  var year = date.getFullYear();
  var month = date.getMonth() < 9 ? '0'+(date.getMonth()+1) : date.getMonth()+1;
  var day = date.getDate() < 10 ? '0'+date.getDate() : date.getDate();
  var hours = date.getHours() < 10 ? '0'+date.getHours() : date.getHours();
  var minutes = date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes();
  return day+'/'+month+'/'+year+', '+hours+':'+minutes;
}

const MatchInfo = (props) =>
<div className='MatchInfo row align-items-center'>
  <div className='MIItem MIOrganiser col-6 col-sm-4 col-md'>
    <div className='MIContent'>
      <a href={props.organiser_url} target='_blank' rel="noopener noreferrer">
        <img src={require('../assets/images/organisers/'+props.organiser+'.png')} alt={props.organiser} />
      </a>
    </div>
  </div>
  <div className='MIItem MITournament col-6 col-sm-4 col-md'>
    <div className='MILabel'>Tournament</div>
    <div className='MIContent'>
      <a href={props.tournament_url} target='_blank' rel="noopener noreferrer">
        {props.tournament}
      </a>
    </div>
  </div>
  <div className='MIItem MIDate col-6 col-sm-4 col-md'>
    <div className='MILabel'>Date & Time</div>
    <div className='MIContent'>
      {getDate(props.date)}
    </div>
  </div>
  {
    props.duration ?
    <div className='MIItem MIDuration col-6 col-sm-4 col-md'>
      <div className='MILabel'>Duration</div>
      <div className='MIContent'>
        {props.duration+' Minutes'}
      </div>
    </div>
    :
    ''
  }
  {
    props.fixture ?
    <div className='MIItem MIDuration col-6 col-sm-4 col-md'>
      <div className='MILabel'>Fixture</div>
      <div className='MIContent'>
        {props.fixture}
      </div>
    </div>
    :
    ''
  }
  <div className='MIItem MIMap col-6 col-sm-4 col-md'>
    <div className='MILabel'>Map</div>
    <div className='MIContent'>
      {props.map}
    </div>
  </div>
  {
    props.demo ?
    <div className='MIItem MIDemo col-6 col-sm-4 col-md'>
      <div className='MILabel'>Demo</div>
      <div className='MIContent'>
        <a href={props.demo} target='_blank' rel="noopener noreferrer">
          <img src={require('../assets/images/icons/download.png')} alt='Download' />
        </a>
      </div>
    </div>
    :
    ''
  }
</div>

export default MatchInfo;
