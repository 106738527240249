import React from 'react';

const MatchLog = (props) => {

  return (
    <div className='MatchLog'>

    </div>
  );
};

export default MatchLog;
