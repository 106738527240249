/*
  Props:
  player = player stats
  maps = map stats
*/

import React, { useState } from 'react';
import PlayerStats from './PlayerStats';
import PlayerMapStats from './PlayerMapStats';

const PlayerStatsContainer = (props) => {
  const [isPlayerStats, setIsPlayerStats] = useState(true);
  const [isTotals, setIsTotals] = useState(false);

  return (
    <div className='PlayerStatsContainer'>
      <div className='PSCHeader'>
        <div className='PSCType custom-control custom-switch'>
          <input onChange={event => setIsPlayerStats(!isPlayerStats)} type="checkbox" className="custom-control-input" id="PlayerMapToggle" />
          <label className='custom-control-label' htmlFor='PlayerMapToggle'>Player / Map Stats Toggle</label>
        </div>
        <div className='PSCTitle'>
          {isPlayerStats ? 'Player Statistics' : 'Map Statistics'}
        </div>
      </div>
      {
        isPlayerStats ?
        <div className='PSContainer'>
          <div className='PSCTotal custom-control custom-switch'>
            <input onChange={event => setIsTotals(event.target.checked)} type="checkbox" className="custom-control-input" id="AvgTotalToggle" />
            <label className='custom-control-label' htmlFor='AvgTotalToggle'>Toggle Totals</label>
          </div>
          {
            isTotals ?
            <PlayerStats
              kills={props.player.kills}
              assists={props.player.assists}
              deaths={props.player.deaths}
              headshots={props.player.headshots}
              wallbangs={props.player.wallbangs}
              noscopes={props.player.noscopes}
              blindkills={props.player.blindkills}
              smokebangs={props.player.smokebangs}
              damage={props.player.damage}
              firstkills={props.player.firstkills}
              firstdeaths={props.player.firstdeaths}
              clutchkills={props.player.clutchkills}
              awpkills={props.player.awpkills}
              plants={props.player.plants}
              defuses={props.player.defuses}
              flashassists={props.player.flashassists}
              flashduration={parseInt(props.player.flashduration).toFixed(2)}
              grenadesthrown={props.player.grenadesthrown}
              utilitydamage={props.player.utilitydamage}
              roundsplayed={props.player.roundsplayed}
              matchesplayed={props.player.matchesplayed}
              k3={props.player.k3}
              k4={props.player.k4}
              k5={props.player.k5}
              v1={props.player.v1}
              v2={props.player.v2}
              v3={props.player.v3}
              v4={props.player.v4}
              v5={props.player.v5}
            />
            :
            <PlayerStats
              kills={(props.player.kills/props.player.matchesplayed).toFixed(2)}
              assists={(props.player.assists/props.player.matchesplayed).toFixed(2)}
              deaths={(props.player.deaths/props.player.matchesplayed).toFixed(2)}
              headshots={(props.player.headshots/props.player.matchesplayed).toFixed(2)}
              wallbangs={(props.player.wallbangs/props.player.matchesplayed).toFixed(2)}
              noscopes={(props.player.matches_kf ? (props.player.noscopes/props.player.matches_kf) : 0).toFixed(2)}
              blindkills={(props.player.matches_kf ? (props.player.blindkills/props.player.matches_kf) : 0).toFixed(2)}
              smokebangs={(props.player.matches_kf ? (props.player.smokebangs/props.player.matches_kf) : 0).toFixed(2)}
              damage={(props.player.damage/props.player.roundsplayed).toFixed(2)}
              firstkills={(props.player.firstkills/props.player.matchesplayed).toFixed(2)}
              firstdeaths={(props.player.firstdeaths/props.player.matchesplayed).toFixed(2)}
              clutchkills={(props.player.clutchkills/props.player.matchesplayed).toFixed(2)}
              awpkills={(props.player.awpkills/props.player.matchesplayed).toFixed(2)}
              plants={(props.player.plants/props.player.matchesplayed).toFixed(2)}
              defuses={(props.player.defuses/props.player.matchesplayed).toFixed(2)}
              flashassists={(props.player.matches_fa ? (props.player.flashassists/props.player.matches_fa) : 0).toFixed(2)}
              flashduration={(props.player.flashduration/props.player.matchesplayed).toFixed(2)}
              grenadesthrown={(props.player.grenadesthrown/props.player.matchesplayed).toFixed(2)}
              utilitydamage={(props.player.utilitydamage/props.player.matchesplayed).toFixed(2)}
              roundsplayed={(props.player.roundsplayed/props.player.matchesplayed).toFixed(2)}
              matchesplayed={props.player.matchesplayed}
              k3={(props.player.k3/props.player.matchesplayed).toFixed(2)}
              k4={(props.player.k4/props.player.matchesplayed).toFixed(2)}
              k5={(props.player.k5/props.player.matchesplayed).toFixed(2)}
              v1={(props.player.v1/props.player.matchesplayed).toFixed(2)}
              v2={(props.player.v2/props.player.matchesplayed).toFixed(2)}
              v3={(props.player.v3/props.player.matchesplayed).toFixed(2)}
              v4={(props.player.v4/props.player.matchesplayed).toFixed(2)}
              v5={(props.player.v5/props.player.matchesplayed).toFixed(2)}
            />
          }
        </div>
        :
        <PlayerMapStats
          maps={props.maps}
        />
      }
    </div>
  );
};

export default PlayerStatsContainer;
