import React from 'react';
import { Link } from 'react-router-dom';
import { plusminus } from '../common/utility'

function colournumber(value) {
  if(value > 0) {
    return 'SBPositive';
  } else if(value < 0){
    return 'SBNegative';
  }
}

const SBPlayer = (props) => {

  return (
    <div className='SBPlayer'>
      <div className='SBAvatar'><Link to={'/player/'+props.id64}><img alt='A' src={props.avatar} /></Link></div>
      <div className='SBName'><Link to={'/player/'+props.id64}>{props.name}</Link></div>
      <div className='SBKills'>{props.kills}</div>
      <div className='SBAssists'>{props.assists}</div>
      <div className='SBDeaths'>{props.deaths}</div>
      <div className='SBHS'>{props.headshots}</div>
      <div className={'SBKD '+colournumber(props.kills - props.deaths)}>
        {plusminus(props.kills - props.deaths)}
      </div>
      <div className='SBADR'>{(props.damage / props.roundsplayed).toFixed(2)}</div>
      <div className={'SBFKD '+colournumber(props.firstkills - props.firstdeaths)}>
        {plusminus(props.firstkills - props.firstdeaths)}
      </div>
      <div className='SBFED'>{props.flashduration.toFixed(2)}</div>
    </div>
  );
};

export default SBPlayer;
