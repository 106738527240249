import React, { useState, useEffect }from 'react';
import { useLocation } from 'react-router';
import '../assets/stylesheets/main.css';
import SearchResult from './SearchResult';
import { apiUrl } from '../App';

function searchPlayers(term) {
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/searchplayers/${encodeURIComponent(term)}`)
    .then(res => res.json())
    .then(json => {
      resolve(json);
    })
    .catch(err => {
      reject(new Error(err));
    });
  });
}

function getSteamData(playerid) {
  return new Promise((resolve, reject) => {
    fetch(`${apiUrl}/getsteamsummary/${playerid}`)
    .then(res => res.json())
    .then(json => {
      if(json.hasOwnProperty('error')) {
        reject(new Error(json.error));
      } else {
        resolve(json);
      }
    })
    .catch(err => {
      reject(new Error(err));
    });
  });
}

const Search = () => {
  const location = useLocation();
  const [results, setResults] = useState();
  const [summary, setSummary] = useState({
    avatar: {
      large: 'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/fe/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg',
      medium: 'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/fe/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_medium.jpg',
      small: 'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/fe/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb.jpg',
    },
    nickname: null,
    id64: null,
  });
  useEffect(() => {
    if (location.state && location.state.userinput) searchPlayers(location.state.userinput).then(res => setResults(res));
    if (location.state && location.state.steamid) getSteamData(location.state.steamid).then(res => setSummary(res));
    else setSummary({
      avatar: {
        large: 'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/fe/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg',
        medium: 'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/fe/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_medium.jpg',
        small: 'https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/fe/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb.jpg',
      },
      nickname: null,
      id64: null,
    });
  }, [location]);
  return (
    <div className='Main col'>
      <div className='col-md-10 offset-md-1'>
        {
          location.state && location.state.hasOwnProperty('steamid') && location.state.steamid ?
          <div className='SearchText'>
            <div className='STPlayer'>
            A steam account was found with the custom id "{location.state.userinput}": &nbsp;
            <a href={`https://steamcommunity.com/profiles/${location.state.steamid}`}>
            <img alt='A' src={summary.avatar.small} />&nbsp;
            {summary.nickname ? summary.nickname : location.state.userinput}
            </a>
              , but they have no recorded matches.</div>
            <div>
              {
                results && results.length > 0 ?
                'These players have similar names and matches on record, they are: '
                :
                ''
              }
            </div>
          </div>
          :
          <div className='SearchText'>
            {
              results && results.length > 0 ?
              `These are the players that have had the term '${location.state.userinput}' in their name:`
              :
              ''
            }
          </div>
        }
        <div className='SearchResults'>
          {
            results ?
            results.length > 0 ?
            results.map((result, i) => {
              return (
                <SearchResult
                  key={i}
                  player_id={result.player_id}
                  name_list={result.name_list}
                />
              );
            })
            :
            'No players found'
            :
            'LOADING SEARCH RESULTS...'
          }
        </div>
      </div>
    </div>
  );
};

export default Search;
